import React from 'react';

const ApiPage = () => {

    return (
        <div className="apiPage-container">
            <h1>api Zip</h1>
            
        </div>
    );
};

export default ApiPage;